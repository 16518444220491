<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewCursoSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-curso-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Agregar Nuevo Curso
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Imagen de Curso -->
          <validation-provider
            #default="validationContext"
            name="Imagen"
            rules="required"
          >
            <b-form-group
              label="Imagen"
              label-for="imagen-curso"
            >
              <b-form-file
                id="imagen-curso"
                v-model="cursoData.file"
                placeholder="Elija un archivo o suéltelo aquí..."
                drop-placeholder="Suelte la imagn aqui..."
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="Titulo Imagen"
            label-for="titulo_imagen"
          >
            <b-form-input
              id="titulo_imagen"
              v-model="cursoData.titulo_imagen"
              trim
              placeholder="..."
            />
          </b-form-group>

          <!-- Titulo Video -->
          <b-form-group
            label="Titulo Video"
            label-for="titulo_video"
          >
            <b-form-input
              id="titulo_video"
              v-model="cursoData.titulo_video"
              trim
              placeholder="Introducción al curso"
            />
          </b-form-group>

          <!-- Url Video -->
          <b-form-group
            label="Enlace o Url del Video"
            label-for="url_video"
          >
            <b-form-input
              id="url_video"
              v-model="cursoData.url_video"
              trim
              placeholder="http://youtube.com/asfavsfa"
            />
          </b-form-group>
          <b-form-group
            label="Mostrar Video"
            label-for="mos-video"
          >
            <b-form-checkbox
              id="mos-video"
              v-model="cursoData.mostrar_video"
            >
              {{ (!cursoData.mostrar_video)?'No Visible':'Visible' }}
            </b-form-checkbox>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar como Nueva Imagen
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

          <b-avatar
            v-for="(imagen, index) in cursoVersionSeleccionado.imagen_presentacion"
            :key="index"
            size="70px"
            rounded
            :src="(imagen.url)?`https://back.blumbit.net/api/public/${imagen.url}`:require('@/assets/images/portrait/small/avatar-s-20.jpg')"
          />
          <div class="d-flex mt-2">

            <code>
              <pre>{{ cursoVersionSeleccionado.imagen_presentacion }}</pre>
            </code>
          </div>
          <div class="d-flex mt-2">
            <pre>{{ cursoVersionSeleccionado.video_presentacion }}</pre>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BFormFile, BFormInvalidFeedback, BFormInput, BFormGroup, BFormCheckbox, BAvatar,
} from 'bootstrap-vue'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import { useMutation } from '@vue/apollo-composable'
import ADD_MEDIA_CURSO from '../../../../graphql/cursos/curso/version/AddMediaCursoVersion.gql'

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    BFormFile,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BAvatar,

    // Form Validation
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCursoSidebarActive',
    event: 'update:is-add-new-curso-sidebar-active',
  },
  props: {
    isAddNewCursoSidebarActive: {
      type: Boolean,
      required: true,
    },
    cursoVersionSeleccionado: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      file: null,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankCursoData = {
      cursoVersionId: props.cursoVersionSeleccionado.id,
      titulo_video: '',
      url_video: '',
      mostrar_video: false,
      titulo_imagen: '',
      file: null,
    }

    const cursoData = ref(JSON.parse(JSON.stringify(blankCursoData)))
    const resetuserData = () => {
      cursoData.value = JSON.parse(JSON.stringify(blankCursoData))
    }

    const { mutate: updateMedia, onDone, onError } = useMutation(ADD_MEDIA_CURSO, () => ({
      variables: cursoData.value,
    }))

    onDone(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Curso Registrado',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    })

    onError(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

    const onSubmit = () => {
      console.log(cursoData.value.file)
      updateMedia()

      emit('refetch-data')
      emit('update:is-add-new-curso-sidebar-active', false)
      /*
      store.dispatch('app-user/addUser', cursoData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
        */
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      cursoData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
