<template>

  <div>
    <curso-list-update-media
      v-if="cursoVersionSeleccionado!=null"
      :is-add-new-curso-sidebar-active.sync="isAddNewCursoSidebarActive"
      :curso-version-seleccionado="cursoVersionSeleccionado"
      @refetch-data="refetchData"
    />
    <!-- Filters -->
    <cursos-list-filters
      v-if="!$apollo.queries.selectCursos.loading && !$apollo.queries.selectModalidades.loading"
      :curso-filter.sync="cursoFilter"
      :modalidad-filter.sync="modalidadFilter"
      :status-filter.sync="statusFilter"
      :curso-options="selectCursos"
      :modalidad-options="selectModalidades"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Cursos</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
              <b-button
                variant="primary"
                :to="{ name: 'curso-version-add' }"
              >
                <span class="text-nowrap">Agregar Curso</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refCursoListTable"
        class="position-relative"
        :busy.sync="loading"
        :items="cursos"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros que conincidan"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
            <strong> Cargando...</strong>
          </div>
        </template>

        <!-- Column: nombre -->
        <template #cell(nombre)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.nombre)"
                :variant="`light-${resolveUserStatusVariant((data.item.publicado)?'active':'inactive')}`"
                :to="{ name: 'curso-version-show', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'curso-version-detalle', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.nombre }}
            </b-link>
            <small
              class="text-muted"
              :variant="`light-${resolveUserStatusVariant((data.item.publicado)?'active':'inactive')}`"
            >
              {{ data.item.curso.nombre }}
            </small> -
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant((data.item.publicado)?'active':'inactive')}`"
              class="text-capitalize"
            >
              {{ data.item.modalidad.nombre }}
            </b-badge>
            <!--small
              class="text-muted"
              :variant="`light-${resolveUserStatusVariant((data.item.publicado)?'active':'inactive')}`">{{ data.item.curso.nombre }} - {{ data.item.modalidad.nombre }}</small-->
          </b-media>
        </template>

        <!-- Column: imagen -->
        <template #cell(imagen)="data">
          <b-media
            vertical-align="center"
            @click="abrirSidebarMedia(data.item)"
          >
            <template #aside>
              <b-avatar
                rounded="sm"
                size="40"
                :src="(data.item.imagen_presentacion[0])?`https://back.blumbit.net/api/public/${data.item.imagen_presentacion[0].url}`:''"
                :text="avatarText(data.item.slug)"
                :variant="`light-${resolveUserStatusVariant((data.item.publicado)?'active':'inactive')}`"
              />
            </template>
            <div class="text-nowrap">
              <b-button
                variant="warning"
                size="sm"
                @click="abrirSidebarMedia(data.item)"
              >
                <span class="align-text-top text-capitalize" />
                <feather-icon
                  :icon="resolveUserRoleIcon(data.item.imagen)"
                  size="18"
                  :class="`text-${resolveUserRoleVariant(data.item.publicado)}`"
                />
              </b-button>
            </div>
          </b-media>
        </template>
        <!-- Column: createdAt -->
        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatFecha(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant((data.item.estado)?'active':'inactive')}`"
            class="text-capitalize"
          >
            {{ (data.item.estado)?'activo':'inactivo' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'curso-version-show', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Mostrar</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'curso-version-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              v-if="!loading"
              class="text-muted"
            >Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ cursosPageInfo.total }} cursos</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!loading"
              v-model="currentPage"
              :total-rows="cursosPageInfo.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import gql from 'graphql-tag'
import vSelect from 'vue-select'
// import store from '@/store'
import { useQuery, useResult } from '@vue/apollo-composable'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import CursosListFilters from './CursoVersionListFilters.vue'
import CursoListUpdateMedia from './CursoListUpdateMedia.vue'
import useCursosList from './useCursoVersionesList'
// import userStoreModule from '../userStoreModule'
import GET_CURSO_VERSIONES from '../../../../graphql/cursos/curso/version/CursoVersiones.gql'
import SELECT_CURSOS from '../../../../graphql/cursos/curso/SelectCursos.gql'
import SELECT_MODALIDADES from '../../../../graphql/cursos/modalidad/SelectModalidad.gql'
// import {  } from '@vue/apollo-composable'

export default {
  components: {
    CursosListFilters,
    CursoListUpdateMedia,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  created() {
    this.cursoFilter = this.$route.params.id
  },
  methods: {
    abrirSidebarMedia(curso) {
      this.isAddNewCursoSidebarActive = true
      this.cursoVersionSeleccionado = curso
    },
  },
  setup() {
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Use toast
    const toast = useToast()
    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    /*
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    */

    const isAddNewCursoSidebarActive = ref(false)
    const cursoVersionSeleccionado = ref(null)

    /*
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]
    */
    /*
    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    */

    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Publicado', value: true },
      { label: 'Borrador', value: false },
    ]

    const formatFecha = fecha => {
      const date = new Date(fecha)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}-${month}-${year}`
    }

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCursoListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      cursoFilter,
      modalidadFilter,
      statusFilter,
    } = useCursosList()

    const {
      result,
      loading,
      onError,
      onResult,
    } = useQuery(GET_CURSO_VERSIONES, {
      limit: perPage,
      page: currentPage,
      search: searchQuery,
      curso: cursoFilter,
      modalidad: modalidadFilter,
      publicado: statusFilter,
    })
    const cursos = useResult(result, null, data => data.getCursosVersionPaginate.data)
    const cursosPageInfo = useResult(result, null, data => data.getCursosVersionPaginate.pageInfo)

    onError(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

    onResult(queryResult => {
      console.log(queryResult.data)
      console.log(queryResult.loading)
      console.log(queryResult.networkStatus)
      console.log(queryResult.stale)
    })

    return {

      // Sidebar
      isAddNewCursoSidebarActive,
      cursoVersionSeleccionado,

      result,
      cursos,
      cursosPageInfo,
      loading,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCursoListTable,
      refetchData,
      formatFecha,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // roleOptions,
      // planOptions,
      statusOptions,

      // Extra Filters
      cursoFilter,
      modalidadFilter,
      statusFilter,
    }
  },
  apollo: {
    /* listacursos: {
      // query: gql`${GET_CURSOS}`,
      query: GET_CURSOS,
      variables: {
        limit: 5,
        page: 1,
      },
    }, */
    selectCursos: SELECT_CURSOS,
    selectModalidades: SELECT_MODALIDADES,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
